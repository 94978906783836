import React from 'react';
import { Divider,Container,BackgroundImage,useMantineTheme,Modal,ScrollArea,Title,Tooltip, Avatar,Card, Image, Text, Badge, Button, Group, SimpleGrid, Center } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconBrandDiscord,IconMailFilled } from '@tabler/icons-react';
import { rem} from '@mantine/core';

export default function PackageTemplate({bookcoachingbutton, coaching_Keyfacts,coaching_overview,coaching_process,image, title, description, difficulty, buttonLabel,buttonEmail, coach1, coach2, coach3, coach4, coach5, coach6, coach1_img,coach2_img,coach3_img,coach4_img,coach5_img,coach6_img}) {
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const theme = useMantineTheme();

  return (
    <>
      <Modal
        opened={opened}
        size="100%"
        radius="lg"
        onClose={close}
        withCloseButton={false}
        overlayProps={{
            color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
          }}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Container fluid>
          <BackgroundImage 
                  src={image}
                  height={200}
                  alt={title}
          >
            <Center>
              <Title m="xl">{title}</Title>
            </Center>
          </BackgroundImage>
          <Divider></Divider>
          <ScrollArea h={600}>
          <SimpleGrid cols={3} spacing="xl"
            breakpoints={[
                { maxWidth: rem(1600), cols: 2, spacing: 'sm' },
                { maxWidth: rem(1100), cols: 1, spacing: 'xs' },
            ]}
          >
            <div>
              <Title>Keyfacts</Title>
              <Divider></Divider>
              <Text>{coaching_Keyfacts}</Text>
            </div>
            <div>
              <Title>Überblick</Title>
              <Divider></Divider>
              <Text>{coaching_overview}</Text>
            </div>
            <div>
              <Title>Ablauf</Title>
              <Divider></Divider>
              <Text>{coaching_process}</Text>
            </div>
          </SimpleGrid>
          </ScrollArea>
          <SimpleGrid>
            <Group m='sm'>
              <Group>
                <Button component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/7YdhYZkcUu" 
                  size='md' 
                  color="lime" 
                  radius={'md'}> 
                  <IconBrandDiscord/>{bookcoachingbutton}
                </Button>
              </Group>
            </Group>
          </SimpleGrid>
        </Container>  
      </Modal>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section component="a">
          <Image
            src={image}
            height={160}
            alt={title}
          />
        </Card.Section>
        <Group position="apart" mt="md" mb="xs">
          <Text weight={500}>{title}</Text>
          {difficulty && (
            <Badge color="lime" variant="light">
              {difficulty}
            </Badge>
          )}
        </Group>
        <Text size="sm" mb="sm" color="dimmed">
          <ScrollArea h={230}>  
          {description}
          </ScrollArea>
        </Text>
        <Group position="apart" mt="md" mb="xs">
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
            {coach1 && (
              <Tooltip label={coach1} withArrow>
                <Avatar src={coach1_img} radius="xl" />
              </Tooltip>
               )}
              {coach2 && (
              <Tooltip label={coach2} withArrow>
                <Avatar src={coach2_img} radius="xl" />
              </Tooltip>
              )}
              {coach3 && (
              <Tooltip label={coach3} withArrow>
                <Avatar src={coach3_img} radius="xl" />
              </Tooltip>
              )}
              {coach4 && (
              <Tooltip label={coach4} withArrow>
                <Avatar src={coach4_img} radius="xl" />
              </Tooltip>
              )}
              {coach5 && (
              <Tooltip label={coach5} withArrow>
                <Avatar src={coach5_img} radius="xl" />
              </Tooltip>
              )}
              {coach6 && (
              <Tooltip label={coach6} withArrow>
                <Avatar src={coach6_img} radius="xl" />
              </Tooltip>
              )}
            </Avatar.Group>
          </Tooltip.Group>
        </Group>
        <Group position='center'>
        {buttonLabel && (
          <Button onClick={open} variant="light" color="lime" mt="md" radius="md">
            {buttonLabel}
          </Button>
        )}
        {buttonEmail && (
          <Button component="a"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:contact@bealien.gg" 
          color="lime" 
          variant="light"
          mt="md" 
          radius="md">
          <IconMailFilled/>  {buttonEmail}
          </Button>
        )}
          <Button component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/7YdhYZkcUu" 
            mt="md"
            variant="light"
            color="lime" 
            radius={'md'}> 
            <IconBrandDiscord/> {bookcoachingbutton}
          </Button>
        </Group>
      </Card>
  </>
  );
}
