
import React, { useState } from 'react';
import { Group,Center,Container, Title, Text, Button, ScrollArea, SimpleGrid} from '@mantine/core';
import WebsiteFooter from './footer';
import headerImage from './res/header_bealien.png'; 
import logoImage from './res/bealienlong.png';
import PackageTemplate from './Packagetemplate';
import CoachCardTemplate from './coachcard';
import avatarbr from './res/coachcontent/avatar_bastianrichter.png';
import avatarlo from './res/coachcontent/avatar_leonotocki.png';
import avatarmt from './res/coachcontent/avatar_michitauscher.png';
import avatarnh from './res/coachcontent/avatar_niklashouben.png';
import avatarnk from './res/coachcontent/avatar_nilsklinkmueller.png';
import avatartp from './res/coachcontent/avatar_tobiaspfeffer.png';
import avatartg from './res/coachcontent/avatar_tobiasgronewald.png';
import bealienheadervideo from './res/beAlienbreit.mp4';
import { rem} from '@mantine/core';
import { IconBrandDiscord,IconMailFilled } from '@tabler/icons-react'; 

const Footerlinks = [
  { link: '/impressum', label: 'Impressum' },
];

const heroStyles = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '700px',
};

const videoStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
  objectFit: 'cover',
};

const overlayStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
  background: 'linear-gradient(to top, rgba(26, 27, 30, 1), transparent)',
};

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
};

const logoStyles = {
  width: '400px',
};

export default function Home() {
const [videoLoaded, setVideoLoaded] = useState(false);

const handleVideoLoaded = () => {
  setVideoLoaded(true);
};
return (
  <div>
    <ScrollArea h={910} type="always" scrollbarSize={6}>
    <div style={{ ...heroStyles }}>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ ...videoStyles, display: videoLoaded ? 'block' : 'none' }}
        onLoadedData={handleVideoLoaded}
      >
        <source src={bealienheadervideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={overlayStyles}></div>
      <Container style={containerStyles} size="md">
        <img src={logoImage} alt="be ALIEN Logo" style={logoStyles} /> 
      </Container>
  </div>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
      <SimpleGrid cols={2} spacing="lg"
        breakpoints={[
          { maxWidth: 'xl', cols: 2, spacing: 'xl' },
          { maxWidth: 'sm', cols: 1, spacing: 'sm' },
        ]}
      >
        <div>
        <Title mb={10}>About us: </Title>
          <Text>
            At beAlien, we strive to improve the simracing experience for drivers of all experience levels. Whether you're a novice or a pro, our personalised coaching will help you reach your full potential on the track. With a team of professional esports drivers and experienced simracing engineers, we offer you high-quality coaching to hone your skills and improve your overall performance. BeFast, beUnstoppable and experience racing on a new level!
          </Text>
        </div>
        <div>
        <Title mb={10}>How it works: </Title>
          <Text>
            <ul>
              <li>1. Join the Bealiengg Discord server. </li>
              <li>2. Navigate to the <strong>"📮│coaching-request"</strong> channel and create a ticket.</li>
              <li>3. In your request, specify the type of coaching you're interested in. You can find a list of the different coaching packages below.</li>
              <li>4. Once your request is submitted, a new channel will be created specifically for you, and a coach will contact you to arrange a suitable date for your session and provide any further details.</li>
              <li>5. After that, you'll receive an invoice, which you will need to pay.</li>
              <li>6. Finally, attend your beAlien coaching session and improve your skills to beFaster!</li>
            </ul>

          </Text>
        </div>
      </SimpleGrid>
  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
    <Center>
      <Group>
      <Button component="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/7YdhYZkcUu" 
        size='lg' 
        color="lime" 
        radius={'lg'} 
        variant='outline'
        mt="lg"> 
        <IconBrandDiscord/>Join our discord
      </Button>
      <Button component="a"
        href="mailto:contact@bealien.gg" 
        size='lg' 
        color="lime" 
        variant='outline'
        radius={'lg'} 
        mt="lg"> 
        <IconMailFilled/> Email us
      </Button>
      </Group>
    </Center>

  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
    <Title mb={10}>Coaching Packages: </Title>
    <SimpleGrid cols={4} spacing="lg"
      breakpoints={[
        { maxWidth: rem(1750), cols: 4, spacing: 'lg' },
        { maxWidth: rem(1650), cols: 3, spacing: 'lg' },
        { maxWidth: rem(1360), cols: 2, spacing: 'md' },
        { maxWidth: rem(800), cols: 1, spacing: 'sm' },
      ]}
    >
    <PackageTemplate
      image={headerImage}
      title="Beginner Coaching"
      description={
        <>
         We recommend our Beginner Coaching to all those who are just starting out with simracing or to those who are already doing it but are still a little more than 2 seconds off the LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            target time.
          </a> We analyse the replay in detail and compare the lap with a reference lap from our coach. The focus here is on analysing fundamental errors and identifying opportunities for improvement. Our aim is to create an understanding of the fundamentals of driving dynamics in order to create the basis for long-term progress across both vehicles and tracks.
        </>
      }
      difficulty="Beginner"
      coaching_Keyfacts={
        <>
          <ul>
            <li>
              <strong>Option 1: Standard tracks</strong>
              <br />
              Duration: approx. 60 minutes
              <br />
              Price: 60 Euro
            </li>
            <li>
              <strong>Option 2: Nordschleife</strong>
              <br />
              Duration: approx. 90 minutes
              <br />
              Price: 80 Euro
            </li>
            <li>
            Target group: recommended for more than 2 seconds difference to LFM Bop {' '}
              <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
                target time
              </a>{' '}
            </li>
            <li>
              Still have questions? Write to us on our Discord! Our coaches are always available to answer your questions.
            </li>
          </ul>
        </>
      }
      coaching_overview={
        <>
          The aim of the coaching is to create an understanding of the basics of driving dynamics in a relaxed atmosphere, in which you also have plenty of opportunities to ask questions, and thus achieve progress across vehicles and tracks. In beginner coaching, your lap is analysed in a replay and compared with a reference lap by our coaches. We not only look at the differences between the two laps, but also go beyond this and explain how they come about and can be eliminated. This identifies harmful behavioural patterns, improves your understanding of driving physics and helps you to develop strategies to eliminate the mistakes and achieve long-term improvements. We recommend this package for anyone who is just starting out with simracing or who is more than two seconds off the{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            target time.
          </a>
        </>
      }
      coaching_process="Before the session, both you and one of our coaches will drive a reference lap under the same conditions with a setup provided by you, which will later be analysed in detail. The lap is saved as a replay and sent to the coach for analysis before the agreed date. In the coaching session itself, we will then discuss and compare the two laps. We will always focus on your current level of knowledge and offer plenty of opportunity for individual questions. After the session, you will receive a summary of the most important points and a replay of the coach's reference round. Our coaches are always available on our Discord if you have any further questions. "
      buttonLabel="More info"
      link=""
      bookcoachingbutton="Book coaching"
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    <PackageTemplate
      image={headerImage}
      title="Advanced Telemetry Coaching"
      description={
        <>
          The Advanced Telemetry Coaching is ideal for those who want to go into more detail or who already have a good basic pace and are less than 2 seconds off the LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            target time. 
          </a>{' '} In this package you get everything as in the Beginner Coaching and even more! We not only analyse the replay, but also the telemetry using MoTeC and compare it with a reference lap from our coach. In this way, we find even the smallest mistakes and use them to develop strategies to eliminate them and achieve long-term progress.
        </>
      }      
      difficulty="Advanced-Pro"
      coaching_Keyfacts={
        <>
          <ul>
            <li>
            <strong>Option 1: Standard routes</strong>
              <ul>
                <li>Duration: approx. 90 minutes</li>
                <li>Price: 80 Euro</li>
              </ul>
            </li>
            <li>
            <strong>Option 2: Nordschleife</strong>
              <ul>
                <li>Duration: approx. 120-180 minutes</li>
                <li>Price: 130 Euro</li>
              </ul>
            </li>
            <li>
              Target group: less than 2 seconds difference to LFM Bop{' '}
              <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
                target time
              </a>{' '}
            </li>
            <li>
            Still have questions? Write to us on our Discord! Our coaches are always available to answer your questions.
            </li>
          </ul>
        </>
      }      
      coaching_overview={
        <>
          The aim of this coaching is to develop a deep understanding of the fundamentals of driving dynamics, analyse errors and identify opportunities for improvement in order to achieve progress across both vehicles and tracks. In Advanced Telemetry Coaching you get everything you get in Beginner Coaching - and more. We will use the telemetry and the replay to analyse your lap in detail and compare it with a reference lap from our coach. Thanks to the telemetry, we will be able to detect even the smallest differences that are otherwise difficult to see in a pure replay analysis. We not only look at the differences between the two laps, but also go beyond this and explain how they come about and can be eliminated. In this way, behavioural patterns that cost lap time are identified and the understanding of the driving physics is improved. Finally, strategies are developed to help you eliminate errors and achieve long-term improvements. We always focus on your current level of knowledge and respond individually to your questions and concerns. We recommend this package for anyone who is less than two seconds off the LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
              target time
          </a>{' '}
          or who is interested in a detailed analysis regardless of pace.
          </>
      }
      coaching_process="Before the session, both you and one of our coaches will drive a reference lap under the same conditions with a setup provided by you, which will later be analysed in detail. Both the replay and the telemetry of the lap will be saved and sent to the coach for analysis before the agreed date. You can find instructions on how to save the MoTeC files on our Discord. In the coaching session itself, we will then discuss and compare the two rounds. We will always focus on your current level of knowledge and offer plenty of opportunity for individual questions. After the session, you will receive a summary of the most important points and a replay of the coach's reference round. Our coaches are always available on our Discord if you have any questions at a later date"
      buttonLabel="More info"
      link=""
      bookcoachingbutton="Book coaching"
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    <PackageTemplate
      image={headerImage}
      title="ACC-MoTeC introduction"
      description="This package gives you an introduction to working with MoTec for ACC. We discuss the most important functions that are needed for ACC and how they can be used profitably. On top, you will receive our esports-tested MoTeC workspace."
      difficulty="Beginner-Pro"
      coaching_Keyfacts={
        <>
          <ul>
            <li>
              Duration: approx. 30-45 minutes
            </li>
            <li>
              Price 40 Euro 
            </li>
            <li>
              Target group: Team managers, coaches and anyone interested in working with MoTeC.
            </li>
            <li>
              Still have questions? Write to us on our Discord! Our coaches are always available for questions
            </li>
          </ul>
        </>
      }
      coaching_overview="In the ACC-MoTeC introduction, we explain the most important functions of MoTeC. Due to the wealth of functions that MoTeC offers, we will focus on the most important functions that we use for ACC in esports and which, in our experience, can be used effectively. In doing so, we will show you the possibilities of driving style analyses as well as important functions that are relevant for setup construction. At the end, you will receive our workspace so that you can access the various functions yourself."
      coaching_process={
        <>
          We recommend installing MoTec i2 Pro before the session. You can download the latest version {' '}
          <a href="https://www.motec.com.au/products/I2?catId=4#DOWNLOADS" target="_blank" rel="noopener noreferrer">
            here
          </a>
        </>
      }
      buttonLabel="More info"
      link=""
      bookcoachingbutton="Book coaching"
      coach1="Bastian Richter"
      coach1_img={avatarbr}
    />
    <PackageTemplate
      image={headerImage}
      title="Individual Coaching"
      description="Your desired coaching is not listed here? Do you have a special request or would you like to do group coaching, for example? Feel free to contact us in the Discord! We will find a suitable solution for you!"
      difficulty=""
      coaching_Keyfacts=""
      coaching_overview=""
      coaching_process=""
      buttonEmail="Email"
      link=""
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    </SimpleGrid>
  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
  <Title mb={10}>beAlien Coaches: </Title>
  <SimpleGrid cols={3} spacing="lg"
      breakpoints={[
        { maxWidth: rem(1650), cols: 3, spacing: 'lg' },
        { maxWidth: rem(1360), cols: 2, spacing: 'md' },
        { maxWidth: rem(800), cols: 1, spacing: 'sm' },
      ]}
    >
      <CoachCardTemplate
        name="Niklas Houben"
        coachimage={avatarnh}
        role="Pro Driver for Mercedes-AMG Esports Team MSI"
        description="Niklas began his career in simracing with F1 Challenge, GTR and rFactor, before later switching to rFactor 2. After a few years break, he returned and competed in Assetto Corsa 1 for the organiser virtualracing.org in the racing team Rutronik Racing. From winter 2020, he took part in Assetto Corsa Competizione (ACC), also for Rutronik Racing. This was followed by a move to GTWR and then to Team Unicorns of Love. Since 2024, he has been a driver for RennWelten Esport and the Mercedes-AMG Team MSI"
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>Lamborghini Real Race 2022 Champion</li>
              <li>IGTC Esports 2024 24h Spa Winner</li>
              <li>IGTC Esports 2024 Champion</li>
              <li>SRO Esports Sprint Europe 2023 P5 Overall</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/niklashouben"
        YoutubeLink="https://www.youtube.com/@NiklasHouben"
        XLink=""
        InstagramLink="https://www.instagram.com/derhouben/"
      />
      <CoachCardTemplate
        name="Bastian Richter"
        coachimage={avatarbr}
        role={
          <>
            Driver and team manager for RennWelten eSports
            <br />
            Coach and race engineer for Mercedes-AMG Esports Team MSI
          </>
        }
        description="Bastian started playing racing games as a child, especially rFactor 1. After a break, he returned to driving in 2015 with Assetto Corsa and took part in league races in the Abgefahren community. He started his esports career at virtualracing.org. In 2020, he switched to Assetto Corsa Competizione with the core team from beAlien and drove for the Unicorns of Love team. Since 2024, he is a driver for RennWelten eSports and founded an academy programme to promote young talent. In addition to driving, he is also a team manager and supports drivers as a coach or race engineer."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st ERWC II - ACC (2nd Overall)</li>
              <li>1st SimGrid x VCO World Cup 2023</li>
              <li>1st SRO Esports 24h Spa, 2023</li>
              <li>2nd SRO Esports IGTC 2023</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink="https://www.youtube.com/@brichtersimracing"
        XLink=""
        InstagramLink="https://www.instagram.com/cb_riding/"
      />
      <CoachCardTemplate
        name="Tobias Gronewald"
        coachimage={avatartg}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team MSI"
        description="Tobias began his sim racing career in 2020 with Assetto Corsa at virtualracing.org, where he gained his first experience in competitive racing. By 2021, he transitioned to Assetto Corsa Competizione (ACC) and joined the team Unicorns of Love.
                    In 2024, Tobias made the move to RennWelten Esports and the Mercedes-AMG Esports Team MSI, competing in the SRO Esports Series. His greatest achievement came that same year, when he claimed the title in the SRO Esports IGTCE Series in the Pro class."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st ERWC II - ACC (2nd Overall)</li>
              <li>4th SRO Esports SIM PRO</li>
              <li>1st SRO Esports 24h Spa, 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/tobiasgronewald"
        YoutubeLink="https://www.youtube.com/@tobiasgronewald4474"
        XLink=""
        InstagramLink="https://www.instagram.com/tobias.gronewald/"
      />
      <CoachCardTemplate
        name="Tobias Pfeffer"
        coachimage={avatartp}
        role="Pro Driver for Mercedes-AMG Esports Team MSI"
        description="Tobias discovered his passion for sim racing in 2011 with Test Drive Unlimited 2. In 2015, he got his first PC and started with Project Cars. Since 2019, he has participated in Esports events in Assetto Corsa Competizione and once raced in the Virtual Le Mans Series. He is currently a driver for Mercedes-AMG Esports Team MSI and RennWelten Esports."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st SRO Esports 24h Spa 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
              <li>2nd SRO Esports IGTC 2023</li>
              <li>3rd SRO Esports IGTC 2022</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/tobiaspfeffer5"
        YoutubeLink="https://www.youtube.com/@TobiasPfeffer1"
        XLink="https://x.com/TobiasPfeffer1"
        InstagramLink="https://www.instagram.com/pfeffer.racing/"
      />
      <CoachCardTemplate
        name="Michi Tauscher"
        coachimage={avatarmt}
        role="Pro Driver for Mercedes-AMG Esports Team HRT by RW"
        description="Michael discovered his passion for racing games in 2013 with MotoGP and used a steering wheel for the first time in F1 2013 and Gran Turismo 5. In 2019 he bought his own simracing rig and started with Assetto Corsa Competizione in 2020. He became part of Sidemax Motorworks and took part in his first esports events in the same year. In 2021 he joined the Unicorns of Love and completed his first SRO Sprint races, followed by his debut in the SRO Endurance Series in 2022. Today he is a driver for Mercedes-AMG Esports Team MSI and Rennwelten Esports"
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st SRO Esports 24h Spa 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
              <li>2nd SRO Esports IGTC 2023</li>
              <li>3rd SRO Esports IGTC 2022</li>
              <li>2nd VCO ERWC II</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink="https://www.youtube.com/@michaeltauscher_uol4474"
        XLink="https://x.com/michi_tauscher"
        InstagramLink="https://www.instagram.com/michi_tauscher/"
      />
      <CoachCardTemplate
        name="Nils Klinkmüller"
        coachimage={avatarnk}
        role="Pro Driver for Mercedes-AMG Esports Team HRT by RW"
        description="Nils began his simracing career in 2016 with Project Cars, where he took his first steps with a steering wheel. In 2019, he joined Wippermann Racing and drove his first race in Assetto Corsa. He took part in his first SRO eSports race in 2021 and joined the Unicorns of Love in the same year before joining Rennwelten Esports and the successful motorsport team Haupt Racing Team in 2024. Since then, he has been a regular in the SRO eSports series and has competed successfully in several competitions."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>3rd SRO Esports 24h Spa 2023</li>
              <li>3rd SRO Esports 9h Kyalami 2022</li>
              <li>5th SRO Esports IGTC 2023</li>
              <li>4th SRO Esports IGTC 2022</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/nyhlz21"
        YoutubeLink=""
        XLink=""
        InstagramLink="https://www.instagram.com/nyhlz21/"
      />
      <CoachCardTemplate
        name="Leon Otocki"
        coachimage={avatarlo}
        role="Pro Driver for Mercedes-AMG Esports Team HRT by RW"
        description="Leon Otocki began his sim racing career in 2016 with F1 2016. In 2018, he transitioned to Assetto Corsa and joined Euronics Gaming in 2019, competing in various events hosted by virtualracing.org. In 2021, he moved to Unicorns of Love and switched to Assetto Corsa Competizione (ACC). By 2022, Leon joined the real-world motorsport team Haupt Racing Team, completing their Esports line-up. Since 2024, he has been racing for RennWelten and BeAlien, continuing his successful sim racing career at a high level."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>3rd SRO Esports 24h Spa 2023</li>
              <li>3rd SRO Esports 9h Kyalami 2022</li>
              <li>5th SRO Esports IGTC 2023</li>
              <li>4th SRO Esports IGTC 2022</li>
              <li>1st ERWC II - ACC (2nd Overall)</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink=""
        XLink=""
        InstagramLink="https://www.instagram.com/letocki/"
      />
    </SimpleGrid>
  </Container>
  <WebsiteFooter links={Footerlinks} />
  </ScrollArea>   
  </div>
);
}
