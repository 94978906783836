import React from "react";
import { createStyles, Container, Group, rem } from '@mantine/core';
import { NavLink } from "react-router-dom";


const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(10),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function WebsiteFooter({links}) {
  const { classes } = useStyles();
  const items = links.map((link) => (
    <NavLink
      className="dimmed"
      key={link.label}
      to={link.link}
      size="sm"
    >
      {link.label}
    </NavLink>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
}
