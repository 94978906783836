
import React, { useState } from 'react';
import { Group,Center,Container, Title, Text, Button, ScrollArea, SimpleGrid} from '@mantine/core';
import WebsiteFooter from './footer';
import headerImage from './res/header_bealien.png'; 
import logoImage from './res/bealienlong.png';
import PackageTemplate from './Packagetemplate';
import CoachCardTemplate from './coachcard';
import avatarbr from './res/coachcontent/avatar_bastianrichter.png';
import avatarlo from './res/coachcontent/avatar_leonotocki.png';
import avatarmt from './res/coachcontent/avatar_michitauscher.png';
import avatarnh from './res/coachcontent/avatar_niklashouben.png';
import avatarnk from './res/coachcontent/avatar_nilsklinkmueller.png';
import avatartp from './res/coachcontent/avatar_tobiaspfeffer.png';
import avatartg from './res/coachcontent/avatar_tobiasgronewald.png';
import bealienheadervideo from './res/beAlienbreit.mp4';
import { rem} from '@mantine/core';
import { IconBrandDiscord,IconMailFilled } from '@tabler/icons-react'; 

const Footerlinks = [
  { link: '/impressum', label: 'Impressum' },
];

const heroStyles = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '700px',
};

const videoStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
  objectFit: 'cover',
};

const overlayStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
  background: 'linear-gradient(to top, rgba(26, 27, 30, 1), transparent)',
};

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
};

const logoStyles = {
  width: '400px',
};

export default function Home() {
const [videoLoaded, setVideoLoaded] = useState(false);

const handleVideoLoaded = () => {
  setVideoLoaded(true);
};
return (
  <div>
    <ScrollArea h={910} type="always" scrollbarSize={6}>
    <div style={{ ...heroStyles }}>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ ...videoStyles, display: videoLoaded ? 'block' : 'none' }}
        onLoadedData={handleVideoLoaded}
      >
        <source src={bealienheadervideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={overlayStyles}></div>
      <Container style={containerStyles} size="md">
        <img src={logoImage} alt="be ALIEN Logo" style={logoStyles} /> 
      </Container>
  </div>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
      <SimpleGrid cols={2} spacing="lg"
        breakpoints={[
          { maxWidth: 'xl', cols: 2, spacing: 'xl' },
          { maxWidth: 'sm', cols: 1, spacing: 'sm' },
        ]}
      >
        <div>
        <Title mb={10}>Über uns: </Title>
          <Text>
            Bei beAlien streben wir danach, das Simracing-Erlebnis für Fahrer aller Erfahrungsstufen zu verbessern. Egal, ob Neuling oder Profi, unser persönliches Coaching hilft dir, dein volles Potenzial auf der Rennstrecke auszuschöpfen.
            
            Mit einem Team aus professionellen Esports-Fahrern und erfahrenen Simracing-Ingenieuren bieten wir dir hochwertiges Coaching, um deine Fähigkeiten zu verfeinern und deine Gesamtleistung zu steigern. Sei beFast, beUnstoppable und erlebe das Rennfahren auf einem neuen Level!
          </Text>
        </div>
        <div>
        <Title mb={10}>Wie es funktioniert: </Title>
          <Text>
            <ul>
              <li>1. Trete dem Bealiengg Discord-Server bei.</li>
              <li>2. Navigiere zum <strong>"📮│coaching-request"</strong>-Kanal und erstelle ein Ticket.</li>
              <li>3. Gib in deiner Anfrage an, welche Art von Coaching du bevorzugst. Eine Liste der verschiedenen Coaching-Pakete findest du unten.</li>
              <li>4. Sobald deine Anfrage eingereicht wurde, wird ein neuer Kanal speziell für dich erstellt, und ein Coach wird sich mit dir in Verbindung setzen, um einen passenden Termin für deine Sitzung zu vereinbaren und weitere Informationen bereitzustellen.</li>
              <li>5. Danach erhältst du deine Rechnung</li>
              <li>6. Nimm an deiner beAlien-Coaching-Sitzung teil und verbessere deine Fähigkeiten. beFaster!</li>
            </ul>
          </Text>
        </div>
      </SimpleGrid>
  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
    <Center>
      <Group>
      <Button component="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/7YdhYZkcUu" 
        size='lg' 
        color="lime" 
        radius={'lg'} 
        variant='outline'
        mt="lg"> 
        <IconBrandDiscord/>  Trete dem Discord bei
      </Button>
      <Button component="a"
        href="mailto:contact@bealien.gg" 
        size='lg' 
        color="lime" 
        variant='outline'
        radius={'lg'} 
        mt="lg"> 
        <IconMailFilled/> Schreibe eine Email
      </Button>
      </Group>
    </Center>

  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
    <Title mb={10}>Coaching Packages: </Title>
    <SimpleGrid cols={4} spacing="lg"
      breakpoints={[
        { maxWidth: rem(1750), cols: 4, spacing: 'lg' },
        { maxWidth: rem(1650), cols: 3, spacing: 'lg' },
        { maxWidth: rem(1360), cols: 2, spacing: 'md' },
        { maxWidth: rem(800), cols: 1, spacing: 'sm' },
      ]}
    >
    <PackageTemplate
      image={headerImage}
      title="Beginner Coaching"
      description={
        <>
          Unser Beginner Coaching empfehlen wir allen, die gerade mit dem Simracing einsteigen oder jenen, die schon dabei sind, denen aber noch etwas mehr als 2 Sekunden zu der LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            Target Time
          </a> fehlen. Wir analysieren hier das Replay im Detail und vergleichen die Runde mit einer Referenzrunde unseres Coaches. Dabei steht im Vordergrund grundlegende Fehler zu analysieren und Verbesserungsmöglichkeiten aufzuzeigen. Unser Ziel ist es, ein Verständnis für fahrdynamische Grundlagen zu schaffen, um so die Basis für sowohl fahrzeug- als auch streckenübergreifend langfristige Fortschritte zu ermöglichen.
        </>
      }
      difficulty="Beginner"
      coaching_Keyfacts={
        <>
          <ul>
            <li>
              <strong>Option 1: Standardstrecken</strong>
              <br />
              Dauer: ca. 60 Minuten
              <br />
              Preis: 60 €
            </li>
            <li>
              <strong>Option 2: Nordschleife</strong>
              <br />
              Dauer: ca. 90 Minuten
              <br />
              Preis: 80 €
            </li>
            <li>
              Zielgruppe: empfohlen bei mehr als 2 Sekunden Differenz zu LFM{' '}
              <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
                Bop Target Time
              </a>{' '}
            </li>
            <li>
              Noch Fragen? Schreib uns auf unserem Discord! Unsere Coaches stehen jederzeit für Fragen zur Verfügung.
            </li>
          </ul>
        </>
      }
      coaching_overview={
        <>
          Ziel des Coachings ist es, in einer entspannten Atmosphäre, bei der Du auch reichlich Gelegenheiten hast, Fragen zu stellen, ein Verständnis für die fahrdynamischen Grundlagen zu schaffen und so auch fahrzeug- und streckenübergreifende Fortschritte zu erzielen. Im Beginner-Coaching wird Deine Runde im Replay analysiert und mit einer Referenzrunde unserer Coaches verglichen. Dabei befassen wir uns nicht nur mit den Unterschieden der beiden Runden, sondern gehen darüber hinaus und erklären auch, wie diese zustande kommen und beseitigt werden können. So werden schädliche Verhaltensmuster erkannt, das Verständnis der Fahrphysik verbessert und anhand dessen Strategien erarbeitet, mit denen du die Fehler beseitigen und langfristige Verbesserungen erzielen kannst. Wir empfehlen dieses Paket für alle, die gerade mit dem Simracing einsteigen oder denen mehr als zwei Sekunden zur{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            Target Time
          </a>{' '}
          fehlen.
        </>
      }
      coaching_process="Vor der Session fährst sowohl Du als auch einer unserer Coaches unter gleichen Bedingungen eine Referenzrunde mit einem von Dir bereitgestellten Setup, die später detailliert analysiert wird. Die Runde wird als Replay gespeichert und dem Coach vor dem vereinbarten Termin zur Analyse zugesendet. In der Coaching Session selbst werden wir dann die beiden Runden besprechen und vergleichen. Hierbei richten wir uns stets nach Deinem aktuellen Kenntnisstand und bieten reichlich Gelegenheit für individuelle Fragen. Nach der Session erhältst Du eine Zusammenstellung der wichtigsten Punkte sowie das Replay der Referenzrunde des Coaches. Für spätere Rückfragen stehen unsere Coaches jederzeit auf unserem Discord zur Verfügung. "
      buttonLabel="Mehr Infos"
      link=""
      bookcoachingbutton="Buche Coaching"
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    <PackageTemplate
      image={headerImage}
      title="Advanced Telemetry Coaching"
      description={
        <>
          Das Advanced Telemetry Coaching ist für alle geeignet, die tiefer ins Detail einsteigen wollen oder die schon eine gute Grundpace haben und weniger als 2 Sekunden zur LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
            Target Time 
          </a>{' '} fehlt. In diesem Paket kriegst Du alles wie beim Beginner Coaching und noch mehr! Wir analysieren hier nicht nur das Replay, sondern auch die Telemetrie mittels MoTeC und vergleichen diese mit einer Referenzrunde unseres Coaches. So finden wir selbst die kleinsten Fehler und erarbeiten anhand dessen Strategien, um diese zu beseitigen und langfristige Fortschritte zu erzielen.
        </>
      }      
      difficulty=""
      coaching_Keyfacts={
        <>
          <ul>
            <li>
            <strong>Option 1: Standardstrecken</strong>
              <ul>
                <li>Dauer: ca. 90 Minuten</li>
                <li>Preis: 80 €</li>
              </ul>
            </li>
            <li>
            <strong>Option 2: Nordschleife</strong>
              <ul>
                <li>Dauer: ca. 120-180 Minuten</li>
                <li>Preis: 130 €</li>
              </ul>
            </li>
            <li>
              Zielgruppe: weniger als 2 Sekunden Differenz zu LFM Bop{' '}
              <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
                Target Time
              </a>{' '}
            </li>
            <li>
              Noch Fragen? Schreib uns auf unserem Discord! Unsere Coaches stehen jederzeit für Fragen zur Verfügung.
            </li>
          </ul>
        </>
      }      
      coaching_overview={
        <>
          Ziel dieses Coachings ist es, ein tiefes Verständnis für fahrdynamische Grundlagen zu entwickeln, Fehler zu analysieren und Verbesserungsmöglichkeiten aufzuzeigen um sowohl fahrzeug- als auch streckenübergreifende Fortschritte zu erzielen. Beim Advanced Telemetry Coaching kriegst Du alles, was es auch beim Beginner Coaching gibt – und noch mehr. Wir werden anhand der Telemetrie sowie des Replays deine Runde im Detail analysieren und mit einer Referenzrunde unseres Coaches vergleichen. Dank der Telemetrie werden wir so selbst die kleinesten Unterschiede erkennen können, die sonst in der reinen Replay-Analyse nur schwer zu sehen sind. Dabei befassen wir uns nicht nur mit den Unterschieden der beiden Runden, sondern gehen darüber hinaus und erklären auch wie diese zustande kommen und beseitigt werden können. So werden Verhaltensmuster erkannt, die Rundenzeit kosten und das Verständnis der Fahrphysik verbessert. Abschließend werden anhand dessen Strategien erarbeitet, mit denen Du die Fehler beseitigen und langfristige Verbesserungen erzielen kannst. Dabei richten wir uns stets nach Deinem aktuellen Kenntnisstand und gehen individuell auf Deine Fragen und Anliegen ein. Wir empfehlen dieses Paket für alle, denen weniger als zwei Sekunden zur LFM{' '}
          <a href="https://lowfuelmotorsport.com/hotlaps" target="_blank" rel="noopener noreferrer">
              Target Time
          </a>{' '}
          fehlen oder die ungeachtet der Pace Interesse an einer detaillierten Analyse haben. 
        </>
      }
      coaching_process="Vor der Session fährst sowohl Du als auch einer unserer Coaches unter gleichen Bedingungen eine Referenzrunde mit einem von Dir bereitgestellten Setup, die später detailliert analysiert wird. Von der Runde wird sowohl das Replay als auch die Telemetrie gespeichert und dem Coach vor dem vereinbarten Termin zur Analyse zugesendet. Eine Anleitung zum Speichern der MoTeC-Dateien findest Du auf unserem Discord. In der Coaching Session selbst werden wir dann die beiden Runden besprechen und vergleichen. Hierbei richten wir uns stets nach Deinem aktuellen Kenntnisstand und bieten reichlich Gelegenheit für individuelle Fragen. Nach der Session erhältst Du eine Zusammenstellung der wichtigsten Punkte sowie das Replay der Referenzrunde des Coaches. Für spätere Rückfragen stehen unsere Coaches jederzeit auf unserem Discord zur Verfügung "
      buttonLabel="Mehr Infos"
      link=""
      bookcoachingbutton="Buche Coaching"
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    <PackageTemplate
      image={headerImage}
      title="ACC-MoTeC-Einführung"
      description="Mit diesem Paket geben wir Dir eine Einführung in die Arbeit mit MoTec für ACC. Wir besprechen die wichtigsten Funktionen, die für ACC gebraucht werden und wie diese gewinnbringend genutzt werden können. On Top erhältst du unseren Esport erprobten MoTeC-Workspace. "
      difficulty=""
      coaching_Keyfacts={
        <>
          <ul>
            <li>
              Dauer: ca. 30-45 Minuten
            </li>
            <li>
              Preis 40 Euro 
            </li>
            <li>
              Zielgruppe: Teammanager, Coaches und alle, die die Arbeit mit MoTeC interessiert
            </li>
            <li>
              Noch Fragen? Schreib uns auf unserem Discord! Unsere Coaches stehen jederzeit für Fragen zur Verfügung
            </li>
          </ul>
        </>
      }
      coaching_overview="In der ACC-MoTeC-Einführung erklären wir die wichtigsten Funktionen von MoTeC. Auf Gtund der Fülle an Funktionen die MoTeC bietet beschränken wir uns hier auf die wichtigsten Funktionen, die wir im Esport Bereich für ACC benutzen und unserer Erfahrung nach gewinnbringend genutzt werden können. Dabei zeigen wir sowohl Möglichkeiten der Fahrstilanalyse, als auch wichtige Funktionen, die für den Setupbau relevant sind. Abschließend erhältst Du unseren Workspace, um selbst auf die vielseitigen Funktionen zugreifen zu können. "
      coaching_process={
        <>
          Vor der Session empfehlen wir, MoTec i2 Pro zu installieren. Die aktuellste Version kannst du {' '}
          <a href="https://www.motec.com.au/products/I2?catId=4#DOWNLOADS" target="_blank" rel="noopener noreferrer">
            hier
          </a>{' '}
          herunterladen. 
        </>
      }
      buttonLabel="Mehr Infos"
      link=""
      bookcoachingbutton="Buche Coaching"
      coach1="Bastian Richter"
      coach1_img={avatarbr}
    />
    <PackageTemplate
      image={headerImage}
      title="Individual Coaching"
      description="Dein Wunsch-Coaching ist hier nicht dabei? Du hast einen besonderes Anliegen oder möchtest zum Beispiel ein Gruppencoaching machen? Kontaktiere uns gerne im Discord! Wir finden eine passende Lösung für Dich!"
      difficulty=""
      coaching_Keyfacts=""
      coaching_overview=""
      coaching_process=""
      buttonEmail="Email"
      bookcoachingbutton="Buche Coaching"
      link=""
      coach1="Niklas Houben"
      coach2="Bastian Richter"
      coach3="Tobias Pfeffer"
      coach4="Nils Klinkmüller"
      coach5="Michael Tauscher"
      coach6="Leon Otocki"
      coach1_img={avatarnh}
      coach2_img={avatarbr}
      coach3_img={avatartp}
      coach4_img={avatarnk}
      coach5_img={avatarmt}
      coach6_img={avatarlo}
    />
    </SimpleGrid>
  </Container>
  <Container fluid mt={20} mb={20} ml={40} mr={40}>
  <Title mb={10}>beAlien Coaches: </Title>
  <SimpleGrid cols={3} spacing="lg"
      breakpoints={[
        { maxWidth: rem(1650), cols: 3, spacing: 'lg' },
        { maxWidth: rem(1360), cols: 2, spacing: 'md' },
        { maxWidth: rem(800), cols: 1, spacing: 'sm' },
      ]}
    >
      <CoachCardTemplate
        name="Niklas Houben"
        coachimage={avatarnh}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team MSI"
        description="Niklas begann seine Karriere im Simracing mit F1 Challenge, GTR und rFactor, bevor er später zu rFactor 2 wechselte. Nach einigen Jahren Pause kehrte er zurück und fuhr in Assetto Corsa 1 für den Veranstalter virtualracing.org im Rennteam Rutronik Racing. Ab Winter 2020 nahm er an Assetto Corsa Competizione (ACC) teil, ebenfalls für Rutronik Racing. Es folgte ein Wechsel zu GTWR und anschließend zu Team Unicorns of Love. Seit 2024 ist er Fahrer für RennWelten Esport und das Mercedes-AMG Team MSI"
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>Lamborghini Real Race 2022 Champion</li>
              <li>IGTC Esports 2024 24h Spa Winner</li>
              <li>IGTC Esports 2024 Champion</li>
              <li>SRO Esports Sprint Europe 2023 P5 Overall</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/niklashouben"
        YoutubeLink="https://www.youtube.com/@NiklasHouben"
        XLink=""
        InstagramLink="https://www.instagram.com/derhouben/"
      />
      <CoachCardTemplate
        name="Bastian Richter"
        coachimage={avatarbr}
        role={
          <>
            Professioneller Fahrer und Teammanager für RennWelten eSports
            <br />
            Coach und Renningenieur für Mercedes-AMG Esports Team MSI
          </>
        }
        description="Bastian begann in seiner Kindheit mit Rennspielen, insbesondere rFactor 1. Nach einer Pause kehrte er 2015 mit Assetto Corsa zum Motorsport zurück und nahm an Liga-Rennen der Abgefahren-Community teil. Seine Esport-Karriere startete er bei virtualracing.org. 2020 wechselte er mit dem Kernteam von beAlien zu Assetto Corsa Competizione und fuhr für das Team Unicorns of Love. Seit 2024 ist er Fahrer bei RennWelten eSports und gründete ein Academy-Programm zur Förderung von Nachwuchstalenten. Neben dem Fahren ist er auch Teammanager und unterstützt Fahrer als Coach oder Renningenieur."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st ERWC II - ACC (2nd Overall)</li>
              <li>1st SimGrid x VCO World Cup 2023</li>
              <li>1st SRO Esports 24h Spa, 2023</li>
              <li>2nd SRO Esports IGTC 2023</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink="https://www.youtube.com/@brichtersimracing"
        XLink=""
        InstagramLink="https://www.instagram.com/cb_riding/"
      />
      <CoachCardTemplate
        name="Tobias Gronewald"
        coachimage={avatartg}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team MSI"
        description="Tobias begann seine Simracing-Karriere 2020 mit Assetto Corsa bei virtualracing.org, wo er seine ersten Erfahrungen im kompetitiven Rennsport sammelte. Bereits 2021 wechselte er zu Assetto Corsa Competizione (ACC) und schloss sich dem Team Unicorns of Love an.
                      Im Jahr 2024 erfolgte der Wechsel zu RennWelten Esports sowie zum Mercedes-AMG Esports Team MSI, wo Tobias in der SRO Esports Serie antrat. Sein größter Erfolg kam im selben Jahr, als er den Titel in der SRO Esports IGTCE Serie in der Pro-Klasse erringen konnte."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st ERWC II - ACC (2nd Overall)</li>
              <li>4th SRO Esports SIM PRO</li>
              <li>1st SRO Esports 24h Spa, 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/tobiasgronewald"
        YoutubeLink="https://www.youtube.com/@tobiasgronewald4474"
        XLink=""
        InstagramLink="https://www.instagram.com/tobias.gronewald/"
      />
      <CoachCardTemplate
        name="Tobias Pfeffer"
        coachimage={avatartp}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team MSI"
        description="Tobias entdeckte 2011 mit Test Drive Unlimited 2 seine Leidenschaft für Simracing. 2015 erhielt er seinen ersten PC und begann mit Project Cars. Seit 2019 hat er an Esports-Events in Assetto Corsa Competizione teilgenommen und einmal in der Virtual Le Mans Series gefahren. Derzeit ist er Fahrer für Mercedes-AMG Esports Team MSI und RennWelten Esports."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st SRO Esports 24h Spa 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
              <li>2nd SRO Esports IGTC 2023</li>
              <li>3rd SRO Esports IGTC 2022</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/tobiaspfeffer5"
        YoutubeLink="https://www.youtube.com/@TobiasPfeffer1"
        XLink="https://x.com/TobiasPfeffer1"
        InstagramLink="https://www.instagram.com/pfeffer.racing/"
      />
      <CoachCardTemplate
        name="Michi Tauscher"
        coachimage={avatarmt}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team HRT by RW"
        description="Michael fand 2013 mit MotoGP seine Begeisterung für Rennspiele und nutzte in F1 2013 und Gran Turismo 5 erstmals ein Lenkrad. 2019 erwarb er sein eigenes Simracing-Rig und fing 2020 mit Assetto Corsa Competizione an. Er wurde Teil von Sidemax Motorworks und nahm noch im selben Jahr an ersten Esport-Events teil. 2021 schloss er sich den Unicorns of Love an und absolvierte seine ersten SRO Sprint-Rennen, gefolgt von seinem Debüt in der SRO Endurance-Serie 2022."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>1st SRO Esports 24h Spa 2022, 2023, 2024</li>
              <li>1st SRO Esports IGTC 2024</li>
              <li>2nd SRO Esports IGTC 2023</li>
              <li>3rd SRO Esports IGTC 2022</li>
              <li>2nd VCO ERWC II</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink="https://www.youtube.com/@michaeltauscher_uol4474"
        XLink="https://x.com/michi_tauscher"
        InstagramLink="https://www.instagram.com/michi_tauscher/"
      />
      <CoachCardTemplate
        name="Nils Klinkmüller"
        coachimage={avatarnk}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team HRT by RW"
        description="Nils begann 2016 seine Simracing-Karriere mit Project Cars und machte dort seine ersten Schritte mit einem Lenkrad. 2019 trat er Wippermann Racing bei und fuhr sein erstes Rennen in Assetto Corsa. Bereits 2021 nahm er an seinem ersten SRO eSports-Rennen teil und schloss sich im selben Jahr den Unicorns of Love an. Seitdem ist er regelmäßig in der SRO eSports-Serie unterwegs und trat 2022 und 2023 in mehreren Wettbewerben erfolgreich an."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>3rd SRO Esports 24h Spa 2023</li>
              <li>3rd SRO Esports 9h Kyalami 2022</li>
              <li>5th SRO Esports IGTC 2023</li>
              <li>4th SRO Esports IGTC 2022</li>
            </ul>
          </>
        }
        TwitchLink="https://www.twitch.tv/nyhlz21"
        YoutubeLink=""
        XLink=""
        InstagramLink="https://www.instagram.com/nyhlz21/"
      />
      <CoachCardTemplate
        name="Leon Otocki"
        coachimage={avatarlo}
        role="Professioneller Fahrer für Mercedes-AMG Esports Team HRT by RW"
        description="Leon Otocki begann seine Simracing-Karriere 2016 mit F1 2016. 2018 wechselte er zu Assetto Corsa und trat 2019 dem Team Euronics Gaming bei, für das er in mehreren Wettbewerben bei virtualracing.org an den Start ging. 2021 folgte der Wechsel zu Unicorns of Love sowie der Umstieg auf Assetto Corsa Competizione (ACC). Im Jahr 2022 schloss er sich dem realen Motorsportteam Haupt Racing Team an und komplettierte dessen Esport-Line-up. Seit 2024 ist Leon für die Teams RennWelten und BeAlien aktiv und setzt seine Simracing-Karriere auf hohem Niveau erfolgreich fort."
        achievements={
          <>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>3rd SRO Esports 24h Spa 2023</li>
              <li>3rd SRO Esports 9h Kyalami 2022</li>
              <li>5th SRO Esports IGTC 2023</li>
              <li>4th SRO Esports IGTC 2022</li>
              <li>1st ERWC II - ACC (2nd Overall)</li>
            </ul>
          </>
        }
        TwitchLink=""
        YoutubeLink=""
        XLink=""
        InstagramLink="https://www.instagram.com/letocki/"
      />
    </SimpleGrid>
  </Container>
  <WebsiteFooter links={Footerlinks} />
  </ScrollArea>   
  </div>
);
}
