import React from 'react';
import { Text,Title,Container,ActionIcon,Anchor } from '@mantine/core';
import { IconChevronsLeft } from '@tabler/icons-react';

function Impressumpage() {
  return <div>
    <Container fluid m={40}>
      <Anchor href="/">
        <ActionIcon variant="light" color="lime" size="xl" radius="md" aria-label="returntohome">
          <IconChevronsLeft style={{ width: '70%', height: '70%' }} stroke={1.5} />
        </ActionIcon>
      </Anchor>
      <Container fluid>
        <Title order={1} mt={40}>Impressum</Title>
        <Title order={3} mt={10}mb={10}>Angaben gemäß § 5 TMG (Telemediengesetz)</Title>
        <Text>beAlien.gg ist eine Seite von</Text>
        <Text>Niklas Houben</Text>
        <Text>Johannes Str. 32, 52525 Waldfeucht</Text>
        <Text>Email: contact@bealien.gg</Text>
        <Text>Telefon auf Anfrage</Text>
        <Text>Umsatzsteuer Identifikationsnummer: 210/5068/1825</Text>
        <Title order={5} mt={10}mb={10}>Urheberrechtshinweis</Title>
        <Text>Alle Inhalte dieser Internetpräsenz, insbesondere Texte, Fotos und Grafiken, sind urheberrechtlich geschützt. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Schranken des Urheberrechtes bedürfen der Zustimmung des jeweiligen Urhebers.  Sollten Sie Inhalte verwenden wollen, wenden Sie sich bitte an den im Impressum aufgeführten Seitenbetreiber. Wer gegen das Urheberrecht verstößt, macht sich strafbar und muss mit einer kostenpflichtigen Abmahnung und Forderungen auf Schadensersatz rechnen.</Text>
        <Title order={5} mt={10}mb={10}>Haftung für Links</Title>
        <Text>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </Text>
        <Title order={5} mt={10}mb={10}>Information gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz)</Title>
        <Text>
          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie online unter: https://ec.europa.eu/consumers/odr/
        </Text>
      </Container>
    </Container>



  </div>;
}

export default Impressumpage;