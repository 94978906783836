import React from 'react';
import { ActionIcon } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';

function LanguageSwitch() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const isEnglish = location.pathname.includes('/en');

  const handleLanguageSwitch = () => {
    const newPath = isEnglish ? location.pathname.replace('/en', '') : `/en${location.pathname}`;
    navigate(newPath);
  };

  return (
    <ActionIcon 
      variant="outline" 
      onClick={handleLanguageSwitch} 
      style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
    >
      {isEnglish ? (
        <span class="flag flag-country-gb"></span>
      ) : (
        <span class="flag flag-country-de"></span>
      )}
    </ActionIcon>
  );
}

export default LanguageSwitch;
