import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import Home from './Home'; 
import HomeEN from './HomeEN'; 
import Impressumpage from './Impressum';
import LanguageSwitch from './langswitch'; 

function App() {
  return (
    <MantineProvider
      theme={{ colorScheme: 'dark', primaryColor: 'lime' }}
      withCSSVariables
      withGlobalStyles
      withNormalizeCSS
    >
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Home />
                <LanguageSwitch />
              </>
            } 
          />
          <Route 
            path="/en" 
            element={
              <>
                <HomeEN />
                <LanguageSwitch />
              </>
            } 
          />
          <Route path="/impressum" element={<Impressumpage />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
