import React from 'react';
import { Group,ActionIcon, useMantineTheme, Paper, ScrollArea, Avatar, Text} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {IconBrandTwitch,IconBrandYoutube,IconBrandX,IconBrandInstagram} from '@tabler/icons-react';

export default function CoachCardTemplate({name, description, coachimage, role,achievements,TwitchLink,YoutubeLink,XLink,InstagramLink}) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const theme = useMantineTheme();

  return (
    <>  
      <Paper radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
        <Avatar
          src={coachimage}
          size={120}
          radius={120}
          mx="auto"
        />
        <Text ta="center" fz="lg" fw={500} mt="sm" mb="xs">
          {name}
        </Text>
        <Text ta="center" fz="sm" fw={200} mb="xs">
          {role}
        </Text>
        <ScrollArea h={250}>  
          <Text ta="center" fs="italic" c="dimmed" fz="sm">
            {description}
          </Text>
          <Text ta="center" fw={200} fz="sm"  mt="xs">
            Achievements:
          </Text>
          <Text ta="center" c="dimmed" fz="sm">
            {achievements}
          </Text>
        </ScrollArea>
        <Group>
        {TwitchLink && (
          <ActionIcon variant="light" component="a" href={TwitchLink} target="_blank" rel="noopener noreferrer">
            <IconBrandTwitch size="1.125rem" />
          </ActionIcon>
        )}
        {YoutubeLink && (
          <ActionIcon variant="light" component="a" href={YoutubeLink} target="_blank" rel="noopener noreferrer">
            <IconBrandYoutube size="1.125rem" />
          </ActionIcon>
        )}
        {XLink && (
          <ActionIcon variant="light" component="a" href={XLink} target="_blank" rel="noopener noreferrer">
            <IconBrandX size="1.125rem" />
          </ActionIcon>
        )}
        {InstagramLink && (
          <ActionIcon variant="light" component="a" href={InstagramLink} target="_blank" rel="noopener noreferrer">
            <IconBrandInstagram size="1.125rem" />
          </ActionIcon>
        )}
        </Group>
      </Paper>
    </>
  );
}

